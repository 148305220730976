<template>
  <div class="container">
    <h1>Welcome to FlowSign.tv</h1>
    <p>Your digital signage solutions</p>
    <p>Engage your audience, with style.</p>
    <button class="button" @click="redirect">Learn More</button>
  </div>
</template>

<script>
export default {
  methods: {
    redirect() {
      window.location.href = 'https://flowsign.tv'; // Your actual site
    }
  }
}
</script>

<style>
  body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7f6;
    color: #333;
  }

  .container {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .logo {
    max-width: 100px;
    margin-bottom: 3.5rem;
  }

  h1 {
    color: #42b983;
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .button {
    padding: 0.8rem 1.5rem;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .button:hover {
    background-color: #399a72;
  }

  .button:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1rem;
    }

    .container {
      padding: 1.5rem;
    }

    .button {
      font-size: 0.9rem;
      padding: 0.7rem 1.3rem;
    }
  }
</style>
